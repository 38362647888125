<template lang="html">
  <div class="tab-text md:ml-4 md:mt-0 mt-4 ml-0">
   <!-- <vx-card title="Configuración del widget" class="mb-base">
      <vs-row vs-w="12" vs-justify="space-between">
        <vs-row vs-w="12">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="12" vs-xs="12">
            <chrome-picker class="picker" v-model="primary" @input="colorPicker('primary')"/>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="12" vs-xs="12" vs-offset="1">
            <vs-row vs-w="12" vs-justify="space-between">
              <h4>Logo barra superior</h4>
              <input class="w-full mb-base" type="file" accept="image/*" @change="obtenerImagenSuperior" name="superior"></vs-input>
              <img :src="widgetImagenURL" fluid alt="Imagen superior" v-if='widgetImagenURL !== ""'></img>
            </vs-row>
          </vs-col>
        </vs-row>
      </vs-row>
      <br>
      <vs-button color="primary" type="border" @click="saveData" :disabled="validateForm">Guardar configuración</vs-button>
    </vx-card>-->
    <vx-card title="" class="">
        <span class="card__header"><h4 class="titulo">URL del widget</h4></span>
<!--<div class="row w-full">
                                <div class="vs-con-input">
                                    <span class="widget-text">Widget</span>
                                    <span class="widget">
                                    <input class="vs-input" 
                                    v-on:focus="$event.target.select()" 
                                    ref="cloneUno" 
                                    readonly 
                                    :value="textdos" >
                                    <button class="btn-widget" @click="copy">Copiar</button>
                                    </span>
                                </div>  
                        </div>-->
                        <div class="row w-full">
                                <div class="vs-con-input">
                                    <span class="widget-text">Widget</span>
                                    <span class="widget">
                                    <input class="vs-input" 
                                    v-on:focus="$event.target.select()" 
                                    ref="cloneDos" 
                                    readonly 
                                    :value="text" >
                                    <button class="btn-widget" @click="copy2">Copiar</button>
                                    </span>
                                </div>  
                        </div>
    </vx-card>
  </div>
</template>

<script>
import { Chrome } from 'vue-color'
var primary = {
  hex: '#194d33',
  rgba: { r: 25, g: 77, b: 51, a: 1 },
  a: 1
}
export default {
  components: {
    'chrome-picker': Chrome
  },
  data() {
    return {
      text: '',
      textdos:'',
      body:'<body>',
      bodyEnd:'</body>',
      email: this.$store.state.AppActiveUser.EMail,
      superior: null,
      primary,
      widgetImagenURL:'',
      key:'',
    }
  },
  mounted(){
    this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
    this.dameInformacion()
    this.generarUrlWidget()
  },
  computed:{
    validateForm(){
      return this.primary == '' && this.superior == null
    },
    bgc () {
      return this.colors
    }
  },
  methods:{
    generarUrlWidget(){
      this.text='https://cotizador.socasesores.com/widget-/#/'+this.key
      this.textdos='https://cotizador.socasesores.com/widget/#/'+this.key
    },
    copy() {
      this.$refs.cloneUno.focus();
      document.execCommand('copy');
    },
    copy2() {
      this.$refs.cloneDos.focus();
      document.execCommand('copy');
    },
    obtenerImagenSuperior(e){
      this.superior= e.target.files[0];
      this.widgetImagenURL = URL.createObjectURL(this.superior);
    },
    saveData(){
      let self=this
      if((typeof this.primary) == 'object'){
        this.primary=this.primary.hex
      }
      let formData = new FormData();
      formData.append('WidgetImagen', this.superior);
      formData.append('MAX_FILE_SIZE','9999999')
      formData.append('request', '{"strApiKey": "'+this.key+'","strMetodo": "RegistraEjecutivo","objEjecutivo": {"EMail": "'+this.email+'","WidgetColorPrimario": "'+this.primary+'"}}');
      this.$axios.post('/',formData,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos guardados exitosamente`,
              position:'top-right'
            })
            this.dameInformacion()
          }else {
            this.$vs.notify({
              title:`Algo salio mal, ${response.data.strError}`,
              position:'top-right',
              color:'danger'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    colorPicker(color){
      if(color=='primary'){
        this.primary=this.primary.hex
      }
    },
    updateValue (value) {
      this.primary=this.primary.hex
      this.colors = value
    },
    dameInformacion(){
      let self=this
      let objRequestDameSolicitante = {
        strApiKey: this.key,
        strMetodo: 'MisDatos',
      }
      this.$axios.post('/',objRequestDameSolicitante,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let data= response.data.objContenido
            if(data.WidgetColorPrimario !== '' && data.WidgetColorPrimario !== null){
              this.primary=data.WidgetColorPrimario;
              this.$vs.theme({
                primary:data.WidgetColorPrimario
              })
            }
            if(data.WidgetImagen !== '' && data.WidgetImagen !== null){
              this.widgetImagenURL = 'https://cotizador.socasesores.com/uploads/'+data.WidgetImagen
            }
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    }
  }
}
</script>

<style lang="css" scoped>

</style>
